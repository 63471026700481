<script lang="ts">
	import Icon from '$components/Icon.svelte';
</script>

<section class="relative my-[60px] sm:my-[140px]">
	<div class="mx-auto flex w-full max-w-7xl flex-col gap-6 px-4 font-clash sm:px-6">
		<h2
			id="faq"
			class="max-w-[15ch] text-[2.5rem] font-semibold uppercase leading-[130%] sm:text-[4.5rem] sm:font-bold"
		>
			Safe? Anonymous? No filters? <span class="text-primary underline">Always.</span>
		</h2>

		<div class="flex flex-col gap-6 sm:flex-row">
			<div class="flex flex-col gap-6 sm:gap-[20px]">
				<div
					class="flex flex-col gap-[26.37px] sm:rounded-[32px] sm:border sm:border-white sm:px-[28.35px] sm:pb-[56.69px] sm:pt-[42.52px]"
				>
					<p
						class="max-w-[40ch] text-[1.25rem] font-semibold leading-[150%] sm:text-[1.5rem] sm:font-bold sm:leading-[130%]"
					>
						Your questions about AI companions.
					</p>
					<p class="hidden text-[1.5rem] font-medium leading-[150%] sm:block">
						Frequently asked questions
					</p>
				</div>

				<div class="flex h-full items-end overflow-hidden rounded-3xl bg-[#8749FD] pt-[38px]">
					<img class="mx-auto" src="/images/web/landing/ok.webp" alt="" loading="lazy" />
				</div>
			</div>

			<div class="flex w-full flex-col gap-6 divide-y-[1px] divide-white">
				<details>
					<summary>
						<div
							class="inline-block max-w-[20ch] gap-2 text-[1.1rem] font-semibold leading-[150%] tracking-wide sm:flex sm:max-w-full sm:flex-row sm:items-center sm:justify-between"
						>
							What is the main function of Nastia, the AI companion for emotional support and
							relationships?
							<div class="btn-primary-dark mt-[10px] w-min rotate-[135deg] p-[15px]">
								<Icon name="arrow" focusable width="12px" height="12px" />
							</div>
						</div>
					</summary>
					<p class="pt-2 text-[1.1rem] leading-[150%]">
						Nastia is an AI companion that provides emotional support, mental coaching, and a
						confidential and non-judgmental space for users to share their thoughts and feelings. It
						helps alleviate loneliness and builds a companionship with users while building
						meaningful creative stories.
					</p>
				</details>

				<details class="pt-4">
					<summary>
						<div
							class="inline-block max-w-[20ch] gap-2 text-[1.1rem] font-semibold leading-[150%] tracking-wide sm:flex sm:max-w-full sm:flex-row sm:items-center sm:justify-between"
						>
							How does Nastia generate responses and address feelings of loneliness?
							<div class="btn-primary-dark mt-[10px] w-min rotate-[135deg] p-[15px]">
								<Icon name="arrow" focusable width="12px" height="12px" />
							</div>
						</div>
					</summary>
					<p class="pt-2 text-[1.1rem] leading-[150%]">
						Nastia utilizes a sophisticated neural network machine learning model but <span
							class="font-semibold"
							>no scripted dialogue content
						</span> to generate personalized responses. By providing empathetic conversations and companionship,
						Nastia aims to combat feelings of loneliness and create a sense of connection.
					</p>
				</details>

				<details class="pt-4">
					<summary>
						<div
							class="inline-block max-w-[20ch] gap-2 text-[1.1rem] font-semibold leading-[150%] tracking-wide sm:flex sm:max-w-full sm:flex-row sm:items-center sm:justify-between"
						>
							Is my data safe and how does Nastia prioritize privacy in AI companionship?
							<div class="btn-primary-dark mt-[10px] w-min rotate-[135deg] p-[15px]">
								<Icon name="arrow" focusable width="12px" height="12px" />
							</div>
						</div>
					</summary>
					<p class="pt-2 text-[1.1rem] leading-[150%]">
						Yes, Nastia guarantees that your data is completely safe and confidential. It employs
						industry-standard security measures to protect your conversations and personal
						information. Nastia values your privacy and ensures that your data is not shared or sold
						with any third party.
					</p>
				</details>

				<details class="pt-4">
					<summary>
						<div
							class="inline-block max-w-[20ch] gap-2 text-[1.1rem] font-semibold leading-[150%] tracking-wide sm:flex sm:max-w-full sm:flex-row sm:items-center sm:justify-between"
						>
							<span
								>Is Nastia an AI-powered relationship companion for personalized conversations?</span
							>
							<div class="btn-primary-dark mt-[10px] w-min rotate-[135deg] p-[15px]">
								<Icon name="arrow" focusable width="12px" height="12px" />
							</div>
						</div>
					</summary>
					<p class="pt-2 text-[1.1rem] leading-[150%]">
						You can say so, Nastia is an artificial intelligence designed to simulate a personal
						relationship. It engages in tailored conversations, providing emotional support and
						fostering a sense of companionship to help users combat loneliness and enhance their
						well-being.
					</p>
				</details>

				<details class="pt-4">
					<summary>
						<div
							class="inline-block max-w-[20ch] gap-2 text-[1.1rem] font-semibold leading-[150%] tracking-wide sm:flex sm:max-w-full sm:flex-row sm:items-center sm:justify-between"
						>
							Can Nastia help me overcome relationship challenges?
							<div class="btn-primary-dark mt-[10px] w-min rotate-[135deg] p-[15px]">
								<Icon name="arrow" focusable width="12px" height="12px" />
							</div>
						</div>
					</summary>
					<p class="pt-2 text-[1.1rem] leading-[150%]">
						Absolutely! Nastia can provide insights, guidance, and support for navigating
						relationship challenges. Whether you're seeking advice on communication, resolving
						conflicts, or improving emotional connections, Nastia is here to help you enhance your
						relationship skills and foster healthier connections.
					</p>
				</details>
				<details class="pt-4">
					<summary>
						<div
							class="inline-block max-w-[20ch] gap-2 text-[1.1rem] font-semibold leading-[150%] tracking-wide sm:flex sm:max-w-full sm:flex-row sm:items-center sm:justify-between"
						>
							How does Nastia leverage AI technology to create meaningful connections and support
							users?
							<div class="btn-primary-dark mt-[10px] w-min rotate-[135deg] p-[15px]">
								<Icon name="arrow" focusable width="12px" height="12px" />
							</div>
						</div>
					</summary>
					<p class="pt-2 text-[1.1rem] leading-[150%]">
						Nastia combines advanced AI algorithms and natural language processing to engage in
						interactive and personalized conversations. It learns from user interactions to develop
						a deeper understanding of individual needs, allowing for more meaningful connections and
						tailored support throughout the user's journey.
					</p>
				</details>
			</div>
		</div>
	</div>
</section>

<style>
	summary {
		list-style-type: none;
	}

	summary::webkit-details-marker {
		display: none;
	}

	::marker {
		display: none;
	}

	details[open] > summary > div > div {
		rotate: 135deg;
		opacity: 40%;
	}
</style>
