<script lang="ts">
	import Icon from '$components/Icon.svelte';
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	export let useModalForLogin: boolean = false;

	const showLogin = () => {
		dispatch('open');
	};
</script>

<section class="mt-[60px] min-h-[calc(100vh-165px)] sm:pb-[140px]">
	<div class="mx-auto w-full max-w-7xl">
		<div class="absolute -top-[170px] left-[35%] -z-10 hidden lg:block">
			<Icon name="scribble" />
		</div>
		<div class="flex flex-col pb-[60px]">
			<!-- Limit CLS while loading image -->
			<div class="flex max-h-[425px] min-h-[425px] w-full items-end justify-end sm:min-h-0">
				<picture>
					<source
						type="image/avif"
						srcset="/images/web/landing/hero-nastia-gray-1200.avif 1200w, /images/web/landing/hero-nastia-gray-860.avif 860w"
					/>
					<source
						type="image/webp"
						srcset="/images/web/landing/hero-nastia-gray-800.webp 800w, /images/web/landing/hero-nastia-gray-600.webp 600w"
					/>
					<img
						fetchpriority="high"
						class="-top-10 right-0 -z-10 object-cover pb-6 sm:h-screen sm:pb-0 min-[640px]:hidden min-[1200px]:absolute min-[1200px]:block"
						src="/images/web/landing/hero-nastia-lg.png"
						alt="chat with nastia companion"
					/>
				</picture>
			</div>
			<div
				class="flex
				max-w-4xl flex-col gap-4 px-4 sm:gap-[max(60px,calc((100vh-600px)/3))] sm:px-6"
			>
				<div
					class="flex flex-col gap-2 font-clash uppercase sm:gap-[max(24px,calc((100vh-600px)/3))]"
				>
					<div
						class="flex flex-col text-[1.5rem] font-semibold leading-[150%] sm:block sm:leading-[120%]"
					>
						<span>Unfiltered Chat</span>
						<span>
							<span class="w-fit bg-quaternary/90 text-black">Uncensored Roleplay</span>
							<span class="sm:hidden"> 💬 </span>
						</span>
						<span>Human-like AI</span>
					</div>

					<h1
						class="max-w-[700px] text-[2.4rem] font-semibold leading-[130%] text-primary sm:text-[5rem] sm:font-bold sm:leading-[140%]"
					>
						Say goodbye to loneliness.
					</h1>
				</div>
				<div id="main-cta" class="flex flex-col gap-[18px] sm:flex-row">
					<a
						href="/#main-cta"
						aria-label="Scroll down to the Chat with Nastia button"
						class="btn-secondary-dark w-min -rotate-90 p-4 sm:hidden"
					>
						<Icon name="arrow" color="white" width="25px" />
					</a>

					<div class="flex flex-col">
						{#if !useModalForLogin}
							<a href="/auth/register" class="btn-secondary-dark w-fit bg-white px-6 text-black">
								<Icon name="hand-heart" height="18px" width="23px" color="black" /> Let me chat with
								my AI
							</a>
						{:else}
							<button
								on:click|stopPropagation={showLogin}
								class="btn-secondary-dark w-fit bg-white px-6 text-black"
							>
								<Icon name="hand-heart" height="18px" width="23px" color="black" /> Let me chat with
								my AI
							</button>
						{/if}
						<span class="mt-1 font-clash text-sm sm:text-center"
							>it's free, no credit card required!</span
						>
					</div>
					<a
						href="https://www.nastia.ai/discord"
						target="_blank"
						class="group pb-5 font-clash text-xl font-medium group-hover:opacity-80 sm:self-center"
					>
						or <span class="underline hover:text-primary hover:opacity-100"
							>join 5k+ humans on discord</span
						>
					</a>
				</div>
			</div>
		</div>
	</div>
</section>
