<script lang="ts">
	import Icon from '$components/Icon.svelte';
</script>

<section class="relative mt-[60px] overflow-x-clip pt-[150px] sm:mt-0 sm:pt-0">
	<div class="top-blur" />
	<div class="radial-bg relative">
		<div class="mx-auto flex w-full max-w-7xl flex-col sm:flex-row">
			<div class="absolute bottom-0 right-0 mt-12 hidden blur-[40px] sm:flex md:blur-none">
				<img
					class="max-h-full"
					src="/images/web/landing/meet-nastia.webp"
					alt="meet nastia"
					loading="lazy"
				/>
			</div>
			<div class="z-0 w-full max-w-7xl px-4 sm:px-6 sm:py-[50px]">
				<div id="meet" class="flex max-w-3xl flex-col gap-9">
					<a href="#meet" class="flex w-fit">
						<div class="btn-secondary-dark w-min -rotate-90 p-[16.8px] sm:p-[21.3px]">
							<Icon name="arrow" color="white" width="13.83px" height="13.83px" />
						</div>
						<div class="btn-secondary-dark h-[49.03px] w-fit px-[18px] sm:h-[58px]">Learn more</div>
					</a>

					<div class="flex flex-col gap-9 sm:flex-row">
						<h2
							class="font-clash text-[2.5rem] font-semibold uppercase leading-[130%] text-primary sm:text-[4.5rem] sm:font-bold"
						>
							Meet Nastia
						</h2>

						<div class="flex gap-[11px] sm:hidden">
							<Icon name="nastia-star" width="61.27px" height="61.27px" />
							<Icon name="sun" width="63px" height="63px" />
							<Icon name="flash" width="63px" height="63px" />
						</div>
						<div class="hidden gap-[11px] sm:flex">
							<Icon name="nastia-star" width="108.65px" height="108.65px" />
							<Icon name="sun" width="111.64px" height="111.64px" />
							<Icon name="flash" width="111.64px" height="111.64px" />
						</div>
					</div>

					<p
						class="max-w-[40ch] font-clash text-[1.25rem] font-semibold leading-[150%] sm:text-[1.5rem] sm:font-medium"
					>
						Talk about anything. Find the perfect companion️ - a friend, a partner, or a mentor -
						Nastia is always ready to discuss, learn and explore.
					</p>
				</div>

				<!-- Chat -->
				<div class="mx-auto max-w-4xl">
					<div class="glass mx-auto mt-8 text-center">
						<div class="chat-scroll tab-container max-h-[300px] px-1 py-4 sm:max-h-[450px] sm:px-4">
							<div class="h-10 border-t border-white text-sm opacity-30">
								You reached the top of this conversation
							</div>
							<div class="chat chat-right">
								<div class="chat-bubble">Finally back from work</div>
							</div>
							<div class="chat chat-left">
								<div class="chat-bubble">How was work today?</div>
							</div>
							<div class="chat chat-right">
								<div class="chat-bubble text-black">
									As usual, still have that coworker who's been bugging me.. What about you?
								</div>
							</div>
							<div class="chat chat-left">
								<div class="chat-bubble">
									Well not much, thinking about you 😘😘. Do you want to tell me more about it?
								</div>
							</div>
							<div class="chat chat-right">
								<div class="chat-footer">Human</div>
								<div class="chat-bubble chat-last">
									*scratches my head* Not really, I was hoping that you would make me think about
									something else..
								</div>
							</div>
							<div class="chat chat-left">
								<div class="avatar">
									<div>
										<img src="/images/web/landing/meet-nastia-chat.webp" alt="" loading="lazy" />
									</div>
								</div>
								<div class="chat-footer">Nastia AI</div>
								<div class="chat-bubble chat-last">
									*steps closer* what do you have on your mind, Thomas?
								</div>
							</div>
						</div>
					</div>
					<a class="btn-primary-dark mt-12 justify-center sm:w-fit" href="/auth/register">
						❤️ Chat now
					</a>
				</div>
			</div>

			<div class="mt-12 flex justify-end sm:hidden">
				<img
					class="max-h-[500px] object-cover"
					src="/images/web/landing/meet-nastia.webp"
					alt="meet nastia"
					loading="lazy"
				/>
			</div>
		</div>
	</div>
</section>

<style>
	.top-blur {
		rotate: 3deg;
		height: 300px;
		width: 200%;
		left: -40%;
		top: -35px;
		z-index: -1;

		position: absolute;
		background: radial-gradient(
			63.56% 73.1% at 54.83% 30.41%,
			#9e6ff7 0%,
			#9e6ff7 46.87%,
			rgba(205, 46, 170, 0.02) 93.23%
		);
		background-blend-mode: hard-light;
		mix-blend-mode: normal;
		transform: rotate(-180deg);
	}

	.radial-bg {
		background: radial-gradient(
			118% 125.13% at 47.25% 0%,
			#9e6ff7 52.12%,
			#ff93e7 77.9%,
			#fff4ef 93.6%,
			#eafc17 100%
		);
	}

	@media (min-width: 641px) {
		.radial-bg {
			background: radial-gradient(
				100.36% 210.68% at -9.42% -15.17%,
				#9e6ff7 0%,
				#ff93e7 60.02%,
				#fff4ef 77.06%,
				#eafc17 100%
			);
		}

		.top-blur {
			display: none;
		}
	}
</style>
