<script lang="ts">
	import SEO from '$components/SEO.svelte';

	import Footer from '$components/Footer.svelte';
	import Header from '$components/Header.svelte';

	import Icon from '$components/Icon.svelte';

	import PoopUp from '$components/PoopUp.svelte';
	import LoginRegister from '$components/v2/web/LoginRegister.svelte';
	import Benefits from './_Benefits.svelte';
	import FAQ from './_FAQ.svelte';
	import Features from './_Features.svelte';
	import Hero from './_Hero.svelte';
	import Meet from './_Meet.svelte';
	import Reviews from './_Reviews.svelte';

	const useModalForLogin = true;

	let showLogin: boolean = false;
	let showRegister: boolean = true;
</script>

<SEO
	title="Nastia | The Uncensored AI Companion"
	description="Chat freely and roleplay with your AI powered companion. Nastia is a 100% unfiltered, NSFW AI designed to be the best human-like companion."
/>

<LoginRegister bind:show={showLogin} {showRegister} />

<Header
	{useModalForLogin}
	on:open={async (event) => {
		showRegister = event.detail.type === 'login' ? false : true;
		showLogin = true;
	}}
/>

{#if false}
	<PoopUp />
{/if}

<main>
	<Hero
		{useModalForLogin}
		on:open={() => {
			showRegister = true;
			showLogin = true;
		}}
	/>

	<Meet />

	<!-- 3 icons section -->
	<section class="mx-auto max-w-7xl">
		<div
			class="mt-[40px] hidden items-start justify-between gap-8 px-4 text-center font-clash text-[1.5rem] font-semibold uppercase leading-[120%] sm:flex"
		>
			<div class="flex max-w-[15ch] flex-col-reverse items-center gap-[42px]">
				Level up your social skills
				<Icon name="sun" color="#6E5BD4" />
			</div>
			<div class="flex max-w-[15ch] flex-col-reverse items-center gap-[42px]">
				Safe. 100% private conversations
				<Icon name="flash" color="#DF91F4" />
			</div>
			<div class="flex max-w-[15ch] flex-col-reverse items-center gap-[42px]">
				Tailored to your needs
				<Icon name="nastia-star" color="#DAF66C" />
			</div>
		</div>
	</section>

	<Features />

	<Benefits />

	<FAQ />

	<Reviews />

	<div class="mx-auto my-12 max-w-7xl px-4 sm:px-6">
		<div class="flex max-h-fit flex-col gap-9 rounded-3xl sm:flex-row-reverse">
			<div class="flex flex-col gap-9 rounded-3xl">
				<div
					class="-mb-9 hidden font-clash text-[1.5rem] font-semibold uppercase leading-[150%] sm:block sm:leading-[120%]"
				>
					blog
				</div>
				<h2
					class="font-clash text-[2.5rem] font-semibold uppercase leading-[130%] sm:text-[4.5rem] sm:font-bold"
				>
					<span class="sm:text-[3.75rem]">Learn about AI</span>
					<span class="bg-white text-black">uncensored</span>
				</h2>

				<p
					class="max-w-[40ch] font-clash text-[1.25rem] font-semibold leading-[150%] sm:text-[1.5rem] sm:font-medium"
				>
					We explore the latest trends, best practices, and cutting-edge technologies in the field
					of AI technology, chatbot development and their impact on our well-being.
				</p>

				<a
					href="/articles"
					class="btn-secondary-dark w-min min-w-max bg-white px-[18px] text-black"
				>
					Read more articles<span class="ml-4 rotate-180"><Icon name="arrow" color="black" /></span>
				</a>
			</div>

			<div class="block w-full sm:hidden lg:block">
				<img
					class="max-h-[500px] w-full overflow-hidden rounded-[12px] object-cover"
					src="/images/web/articles/articles-header.webp"
					alt="black and white humanoid robot"
					loading="lazy"
				/>
			</div>
		</div>
	</div>

	<!-- Link to discord -->
	<div class="mx-auto my-12 max-w-7xl px-4 sm:px-6">
		<div
			class="max-w-[10ch] font-clash text-[2.5rem] font-semibold uppercase leading-[3.25rem] sm:text-left sm:text-[5.4rem] sm:font-bold sm:leading-[6.5rem]"
		>
			Chat, Roleplay, Grow.
		</div>
	</div>
</main>

<Footer isGuidelinesShowing />
